<template>
    <div class="map-card" :class="['card', autoWidth ? 'card-block': '', isHighlighted, typeClass]" @click="highlight">
        <h5>{{ subCategory }}</h5>
        <h3 class="title">{{ item.title }}</h3>
        <p class="address mt-auto" v-html="address"></p>
        <router-link :to="linkTo" alt="Link zum Profil" class="btn btn-icon btn-round btn-next">
            <span class="material-icons-outlined show-ltr" aria-hidden="true">navigate_next</span>
            <span class="material-icons-outlined show-rtl" aria-hidden="true">navigate_before</span>   
        </router-link>
    </div>
</template>

<script>
    import { getSubCategory } from '@/utils/lupe-helpers';
    import { getFieldValues, getFirstFieldValue } from '@/utils/helpers';

    export default {
        name: 'OrteMapCard',
        props: {
            item: {
                type: Object,
                default: null,
                required: true
            },
            autoWidth: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            linkTo(){
                return "/orte/"+this.item.institution_id;
            },
            category(){
                var value = getFirstFieldValue(this.item,'kategorie');
                return value!=null?value:'';
            },
            subCategory(){
             return getSubCategory(this.item,this.category);
         },
         typeClass(){
            return this.category!==null?this.category.replace(/\s/g, '').replace(/[^A-Za-z0-9\-_]/g, ''):'';
        },
        isHighlighted(){
            if(this.item.hasOwnProperty("highlight") && this.item.highlight == true){
                return "highlighted";
            }
            return "not-highlighted"
        },
        address(){
            var address = getFieldValues(this.item,'adresse');
            if(address!==null){
                var gna = "";
                if(address.hasOwnProperty('street') && address.street!==null){
                    gna = gna+address.street+'<br>';
                }
                if(address.hasOwnProperty('zipcode') && address.zipcode!==null){
                    gna = gna+address.zipcode+', ';
                }
                if(address.hasOwnProperty('city') && address.city!==null){
                    gna = gna+address.city;
                }
                return gna;
            }
            return "";
        }
    },
    methods:{
       getFieldValues,
       getFirstFieldValue, 
       highlight(event){
        this.$emit('highlight',{sender:'card', content: this.item});
    }
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .card {
        display: flex;
        position: relative;
        min-width: 285px;
        border: none;
        border-radius: 0px;
        margin-top: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        box-shadow: 1px 2px 10px 1px #00000040;
        padding: 15px;
        cursor: pointer;


        &.highlighted{
            border: 3px solid rgb(46, 85, 165);
        }

        &.Freizeit{
            &.highlighted{
                border: 3px solid $primary;
            }

            h5{  
                color: $primary;
            }
        }

        &.BeratungHilfe{
            &.highlighted{
                border: 3px solid $wb-meganta;
            }

            h5{  
                color: $wb-meganta;
            }
        }

        &.AusbildungWeiterbildung{
            &.highlighted{
                border: 3px solid $wb-jade;
            }

            h5{  
                color: $wb-jade;
            }

        }

        .category {
            color: $primary;
        }

        .title {
            font-size: 17px;
            line-height: 24px;
            margin-top: 10px;
            margin-bottom: 20px;
            min-height: 26px;
            max-width: calc(100% - 48px);
        }

        .address{
            font-size: 14px;
        }

        .btn-next {
            position: absolute;
            right: 15px;
            bottom: 15px;
        }

        &:first-child {
            margin-left: 15px;
        }

        &-block {
            margin-right: 0;
            width: 100%;
        }
    }

    body[dir="rtl"] {
        .card{
            margin-left: 15px;
            margin-right: 0;

            .btn-next {
                right: auto;
                left: 15px;
            }

            &.card-block {
                margin-left: 0;
            }

            &:first-child {
                margin-right: 15px;
            }
        }
    }
</style>
